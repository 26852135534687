import { create } from "zustand";
import { IToastStore } from "@/types/Toast/IToastStore";
const MAX_TOASTS = 5;

export const useToastStore = create<IToastStore>((set, get) => ({
  toasts: [],
  addToast: (toast) => {
    const toasts = get().toasts;
    if (toasts.length >= MAX_TOASTS) {
      toasts.shift();
    }
    set({ toasts: [...toasts, toast] });
  },
  removeToast: (id) => {
    const toasts = get().toasts;
    set({ toasts: toasts.filter((toast, index) => toast.id !== id) });
  },
  clearToasts: () => {
    set({ toasts: [] });
  },
}));
