import { useToastStore } from "@/stores/Toast/Toaster";
import { useCallback, useMemo } from "react";
import { IToastProps } from "@/types/Toast/IToast";

export const showToast = (toast: IToastProps) => {
  const id =
    toast.id ??
    Date.now().toString(36) + Math.random().toString(36).substring(2, 5);
  const store = useToastStore.getState();
  store.addToast({ ...toast, id: id });
  setTimeout(() => store.removeToast(id), 3000);
};

const useToast = () => {
  return useMemo(
    () => ({
      info: (toast: Omit<IToastProps, "type">) =>
        showToast({ ...toast, type: "info" }),
      success: (toast: Omit<IToastProps, "type">) =>
        showToast({ ...toast, type: "success" }),
      warning: (toast: Omit<IToastProps, "type">) =>
        showToast({ ...toast, type: "warning" }),
      error: (toast: Omit<IToastProps, "type">) =>
        showToast({ ...toast, type: "error" }),
      clear: () => useToastStore.getState().clearToasts(),
    }),
    []
  );
};

export default useToast;
