import axios from "axios";
import { getSession, signOut } from "next-auth/react";
import { env } from "@/utils/env";
import useToast from "@/hooks/useToast";
import { showToast } from "@/utils/showToast";
import showDialog, { closeDialog } from "@/utils/showDialog";
import Text from "@/components/ui/Text";
import Button from "@/components/ui/Buttons/Button";

export const ApiRequest = () => {
  const instance = axios.create({
    baseURL: env.NEXT_PUBLIC_BASE_API_URL,
  });
  instance.interceptors.request.use(async (request) => {
    const session = await getSession();
    if (session) {
      request.headers["Authorization"] = `Bearer ${session.auth.token}`;
    }
    request.headers["Content-Type"] = "multipart/form-data";
    return request;
  });
  instance.interceptors.response.use(
    async function (response) {
      const errorData =
        response.data.responseData?.error !== undefined
          ? response.data.responseData?.error.join("</br>")
          : "";
      if (
        response?.data?.responseHeader?.statusCode == "200" &&
        response?.data?.responseHeader?.statusHeader != undefined
      ) {
        switch (response?.data?.responseHeader?.statusVisibleType) {
          case "1":
            showToast({
              hint:
                response?.data.responseHeader.statusMessage + "\n" + errorData,
              title: response?.data?.responseHeader?.statusHeader,
              type: "success",
            });
            break;
          default:
            break;
        }
      }
      if (response?.data?.responseHeader?.statusCode != "200") {
        switch (response?.data?.responseHeader?.statusVisibleType) {
          case "1":
            showToast({
              hint:
                response?.data.responseHeader.statusMessage + "\n" + errorData,
              title: response?.data?.responseHeader?.statusHeader,
              type: "error",
            });
            break;
          case "2":
            showDialog({
              id: "error-response",
              title: response?.data?.responseHeader?.statusHeader,
              content: (
                <div className="flex flex-col gap-4">
                  <Text variant="h6" as="h6">
                    {response?.data?.responseHeader?.statusMessage}
                  </Text>
                  {response.data.responseData?.error && (
                    <ul className="flex flex-col gap-2 list-disc pl-4">
                      {response.data.responseData?.error?.map(
                        (error: any, index: number) => (
                          <li
                            className="!text-caption px-2 py-2 rounded-component border border-extended_neutral-N100 bg-extended_red-R400/10"
                            key={index}
                          >
                            {error}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              ),
              footer: (
                <div className="flex w-full gap-2">
                  <Button
                  fullWidth
                    onClick={() => closeDialog("error-response")}
                    color="danger"
                  >
                    Kapat
                  </Button>
                </div>
              ),
            });
            break;
          default:
            break;
        }
      }
      if (response?.data?.responseHeader?.statusCode === "403") {
        showToast({
          hint:
            response?.data.responseHeader.statusMessage +
            "\n" +
            errorData,
          title: response?.data?.responseHeader?.statusHeader,
          type: "error",
        });  
        signOut({ redirect: true,callbackUrl:"/" }).then(() => {
        });
      }
      return response;
    },
    function (error) {
      const errorData =
        error?.response?.data?.responseData !== null
          ? error?.response?.data.responseData?.error.join("\n")
          : "";

         
      if (error?.response?.data?.responseHeader?.statusHeader) {
        switch (error?.response?.data?.responseHeader?.statusVisibleType) {
          case "1":
            showToast({
              hint:
                error?.response?.data.responseHeader.statusMessage +
                "\n" +
                errorData,
              title: error?.response?.data?.responseHeader?.statusHeader,
              type: "error",
            });
            break;
          case "2":
            break;
          default:
            break;
        }
      }

      return Promise.reject(error);
    }
  );
  return instance;
};
