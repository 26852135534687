import { create } from "zustand";
import { IDialogStore } from "@/types/Dialog/IDialogStore";

export const useDialogStore = create<IDialogStore>((set, get) => ({
  dialogs: [],
  addDialog: (dialog) => {
    const dialogs = get().dialogs;
    set({ dialogs: [...dialogs, dialog] });
  },
  removeDialog: (id) => {
    const dialogs = get().dialogs;
    set({ dialogs: dialogs.filter((dialog, index) => dialog.id !== id) });
  },
  clearDialogs: () => {
    set({ dialogs: [] });
  },
}));
