import { IDialogProps } from "@/types/Dialog/IDialog";
import { useDialogStore } from "@/stores/Dialog/Dialog";

export const showDialog = (dialog: IDialogProps) => {
  const id =
    dialog.id ??
    Date.now().toString(36) + Math.random().toString(36).substring(2, 5);
  const store = useDialogStore.getState();
  store.addDialog({ ...dialog, id: id });
};

export default showDialog;

export const closeDialog = (id: string) => {
  const store = useDialogStore.getState();
  store.removeDialog(id);
};
